import { MediaSpot } from 'ui/components/3-organisms/MediaSpot/MediaSpot';

export const MediaSpotFeature: React.FC<Content.MediaSpot> = ({ content, settings }) => {
	return (
		<MediaSpot
			bgColor={content?.properties?.color}
			heading={content?.properties?.headline}
			trumpetHeading={content?.properties?.kickerFirstWord}
			contentHeading={content?.properties?.textHeading}
			contentParagraph={content?.properties?.text}
			readMoreText={content?.properties?.readMoreText}
			readMoreButtonText={content?.properties?.readMoreButtonText}
			image={
				content?.properties?.image
					? {
							url: content.properties.image.url,
							altText: content.properties.image.properties?.altText,
							focalPoint: content.properties.image.focalPoint,
							imageCropping: content?.properties?.imageCropping,
					  }
					: null
			}
			video={
				content?.properties?.video
					? {
							url: content.properties.video.url,
							poster: content.properties.video.poster,
							autoplay: settings?.properties?.autoplay || false,
							playOnMouseover: settings?.properties?.playOnMouseover || false,
					  }
					: null
			}
			linkButton={
				content?.properties?.link
					? {
							children: content.properties.link.name,
							href: content.properties.link.url,
							target: content.properties.link.target,
							title: content.properties.link.name,
					  }
					: null
			}
		/>
	);
};
