import styles from './MediaSpot.module.scss';
import { Heading } from 'ui/components/1-atoms/Heading/Heading';
import { Picture } from 'ui/components/1-atoms/Media/Picture/Picture';
import { Player } from 'ui/components/1-atoms/Media/Player/Player';
import { Container } from 'ui/components/4-habitats/Container/Container';
import { Grid } from 'ui/components/4-habitats/Grid/Grid';
import { GridCell } from 'ui/components/4-habitats/Grid/GridCell';
import { TopLevelGrid } from 'ui/components/4-habitats/TopLevelGrid/TopLevelGrid';
import { TopLevelGridCell } from 'ui/components/4-habitats/TopLevelGrid/TopLevelGridCell';
import ColorOptions from 'helpers/types';
import classNames from 'classnames';
import { Paragraph } from 'ui/components/1-atoms/Paragraph/Paragraph';
import { LinkButton, LinkButtonProps } from 'ui/components/1-atoms/Action/LinkButton/LinkButton';
import { useInView } from 'react-intersection-observer';
import { useEffect, useState } from 'react';
import { RichText } from 'ui/components/1-atoms/RichText/RichText';

export interface MediaSpotProps {
	heading: string;
	contentHeading: string;
	contentParagraph: string;
	bgColor?: ColorOptions;
	trumpetHeading?: string;
	image?: {
		url: string;
		altText?: string;
		imageCropping?: 'cover' | 'contain';
		focalPoint?: { left: number; top: number };
	};
	video?: {
		url: string;
		poster?: string;
		autoplay?: boolean;
		playOnMouseover?: boolean;
	};
	linkButton?: LinkButtonProps;
	readMoreText: string;
	readMoreButtonText: string;
}

export const MediaSpot: React.FC<MediaSpotProps> = ({
	bgColor = 'white',
	trumpetHeading,
	heading,
	image,
	video,
	contentHeading,
	contentParagraph,
	linkButton,
	readMoreText,
	readMoreButtonText,
}) => {
	const { ref, inView } = useInView({
		threshold: 0.5,
		triggerOnce: false,
	});

	const [isPlaying, setIsPlaying] = useState(false);
	const [isTouchDevice, setIsTouchDevice] = useState(false);
	const onVideoMouseEnter = () => {
		if (video?.playOnMouseover) {
			setIsPlaying(true);
		}
	};

	useEffect(() => {
		const isTouch = window?.matchMedia('(hover: none)').matches;
		setIsTouchDevice(isTouch);
	}, []);

	return (
		<div ref={ref} className={classNames(styles.MediaSpot, styles[`u-color___${bgColor}`])}>
			<Container width="Standard">
				<TopLevelGrid container wrap>
					<TopLevelGridCell desktopWidth="16col" mobileWidth="100">
						{trumpetHeading && <p className={styles.MediaSpot_trumpetHeading}>{trumpetHeading}</p>}

						{heading && (
							<Heading className={styles.MediaSpot_heading} headingLevel="h2" style="xl">
								{heading}
							</Heading>
						)}
					</TopLevelGridCell>
					<TopLevelGridCell desktopWidth="22col" mobileWidth="100" desktopOffset="2col" offsetDirection="right">
						<Grid container wrap largeGutter>
							{(video || image) && (
								<GridCell desktopWidth="66" mobileWidth="100" className={styles.MediaSpot_media}>
									<div onMouseEnter={onVideoMouseEnter}>
										{video ? ( //video might need to be in a 16,9 ratio
											<Player
												url={video.url}
												isLight={video.autoplay || isPlaying || (isTouchDevice && inView) ? false : true}
												playing={isPlaying || inView ? true : false}
												muted={true}
												loop={true}
												controls={false}
												poster={image?.url}
											/>
										) : (
											image && (
												<Picture
													className={styles.MediaSpot_imgWrapper}
													url={image.url}
													altText={image.altText}
													imageCropping={image.imageCropping ?? 'cover'} //todo? is this right?
													focalPoint={image.focalPoint}
													sizes="(max-width: 768px) 100vw, (max-width: 1728px) 66vw, 1150px"
												/>
											)
										)}
									</div>
								</GridCell>
							)}

							<GridCell desktopWidth="33" mobileWidth="100" className={styles.MediaSpot_contentText}>
								{contentHeading && (
									<Heading headingLevel="h3" className={styles.MediaSpot_contentHeading}>
										{contentHeading}
									</Heading>
								)}

								{contentParagraph && (
									<RichText
										readMoreText={readMoreText}
										readMoreButtonText={readMoreButtonText}
										content={contentParagraph}
										className={styles.MediaSpot_contentParagraph}
									/>
								)}

								{linkButton && (
									<LinkButton style="primary" href={linkButton.href} target={linkButton.target} inheritsColor>
										{linkButton.title}
									</LinkButton>
								)}
							</GridCell>
						</Grid>
					</TopLevelGridCell>
				</TopLevelGrid>
			</Container>
		</div>
	);
};
